<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">

		<div class="form-body">
			<el-form ref="form" :model="form" :rules="rules" label-width="150rem">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
				</el-form-item>

				<el-form-item label="性别" prop="gender">
					<el-select :popper-append-to-body="false" v-model="form.gender" placeholder="请选择您的性别">
						<el-option label="男" :value="1"></el-option>
						<el-option label="女" :value="0"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="校区">
					<!-- <el-input v-model="form.school_name" placeholder="请选择您的校区" disabled></el-input> -->
					{{ form.school_name }}
				</el-form-item>

				<el-form-item label="年级">
					<!-- <el-input v-model="form.grade" placeholder="请选择您的年级" disabled></el-input> -->
					{{ form.grade }}
				</el-form-item>

				<el-form-item label="行政班班级" prop="administrative_class_id">
					<el-select :popper-append-to-body="false" v-model="form.administrative_class_id" placeholder="请选择">
						<el-option v-for="i in genderList" :label="i.class_name" :value="i.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="学习方向">
					{{ form.learning_direction }}
				</el-form-item>

				<el-form-item label="专业方向">
					{{ form.major }}
				</el-form-item>

				<el-form-item label="身份证号">
					<!-- <el-input v-model="form.id_card" placeholder="请输入您的身份证" disabled></el-input> -->
					{{ form.id_card }}
				</el-form-item>

				<el-form-item label="学号">
					<!-- <el-input v-model="form.students_no"  placeholder="请输入您的学号" disabled></el-input> -->
					{{ form.students_no }}
				</el-form-item>

				<el-form-item label="登录密码">
					<el-input v-model="form.password" placeholder="******" show-password="" type="password"></el-input>
				</el-form-item>

				<el-form-item label="当前账号状态">
					<span>{{ data.status }}</span>
				</el-form-item>

				<el-form-item label="禁用原因" v-if="data.status === '禁用'">
					<span>{{ data.type }}</span>
				</el-form-item>

				<el-form-item label="禁用原因备注" v-if="data.status === '禁用'">
					<span>{{ data.remarks }}</span>
				</el-form-item>
			</el-form>

			<!-- 面板展示 -->
			<!--			<el-descriptions class="margin-top" :column="3" border style="minwidth: 500rem; margin: 50rem 50rem 50rem 50rem">-->
			<!--				<el-descriptions-item style="width: 150rem">-->
			<!--					<template slot="label">创建人:</template>-->
			<!--					{{ form.creator == null ? '' : form.creator }}-->
			<!--				</el-descriptions-item>-->
			<!--				<el-descriptions-item>-->
			<!--					<template slot="label">创建时间:</template>-->
			<!--					{{ form.created_at == null ? '' : form.created_at }}-->
			<!--				</el-descriptions-item>-->
			<!--			</el-descriptions>-->
			<div style="padding: 20rem 68rem">
				<div>账号操作记录</div>
				<div v-if="historyData.length" style="margin-top: 24rem">
					<div v-for="item in historyData" class="history-box flex flex-between">
						<div class="flex flex-between" style="width: 10rem">
							<div class="step-box" style="padding-top: 16rem">
								<div class="cir"></div>
								<span class="cir-line"></span>
							</div>
						</div>
						<div style="width: calc(100% - 50rem);padding: 0 0 30rem">
							<p>{{ item.change_detail }}</p>
						</div>
					</div>
				</div>
				<t-result v-if="!historyData.length" :type="'empty'"></t-result>
			</div>
		</div>

		<!-- 底部按钮 -->


		<!-- 点击选择角色弹窗 -->
		<el-dialog title="选择角色" :visible.sync="dialogVisible" width="50%">
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>

</edit-template>
</template>

<script>
import {obtain, submit} from "@/api/account-management";
import {system_history} from "@/api";

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
		return {
			historyData: [],
			checkData: [],
			form: {
				name: '', // 姓名
				gender: '', // 性别
				school_name: '', //校区
				grade: '', //年级
				administrative_class_id: '', //行政班级
				password: '', // 密码
				school: '', // 校区
				roles: [], // 角色
				students_no: '', //学号
				id_card: '', //身份证
				learning_direction: '', // 学习方向
			},
			genderList: [],
			genderListTo: [],
			dialogVisible: false,
			schoolList: [],
			rules: {
				name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
				gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
				school_name: [{ required: true, message: '请选择校区', trigger: 'change' }],
				learning_direction: [{ required: true, message: '请选择学习方向', trigger: 'change' }],
				grade: [{ required: true, message: '请选择年级', trigger: 'change' }],
				administrative_class_id: [{ required: true, message: '请选择行政班级', trigger: 'change' }],
				students_no: [{ required: true, message: '请输入学号', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				roles: [{ required: true, message: '请选择角色', trigger: 'change' }],
				id_card: [{ required: true, message: '输入身份信息', trigger: 'change' }],
			},
			data: {},
		};
	},

	methods: {
		save(form) {
			this.$refs[form].validate(valid => {
				if (valid) {
					this.form.roles = this.form.roles.toString();
					let params = {
						name: this.form.name,
						gender: this.form.gender,
						administrative_class_id: this.form.administrative_class_id,
						password: this.form.password,
						learning_direction: this.form.learning_direction
					}
					submit(this.$route.query.id, params).then(res => {
						if (res.data.error.errorCode != 0) {
							this.$notify.error({
								title: '修改出错',
								message: res.data.error.errorUserMsg,
							});
						} else {
							this.$notify({
								title: '修改成功',
								message: '保存成功',
								type: 'success',
							});
							this.$router.back();
						}
					});
				} else {
					this.$message.error('请完善您的信息');
					return false;
				}
			});
		},
		open() {
			this.$confirm('是否取消编辑', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.$router.back();
			}).catch(() => {});
		},
		choose() {
			this.dialogVisible = true;
		},
	},

	created() {
		obtain(this.$route.query.id).then(res => {
			this.form = res.data.data;
			if (this.form.learning_direction === 0) {
				this.form.learning_direction = 1;
			}
			this.$_axios.get('/site/class-data?school_id=' + this.form.school + '&grade=' + this.form.grade_id).then(res => {
				this.genderList = res.data.data
			})
		});

		// 学生账号禁用
		this.$_axios2.get('api/account-student/student/status?id=' + this.$route.query.id).then(res => {
			this.data = res.data.data;
		});


		// 获取学生学习方向
		this.$_axios2.get('/api/account-student/student/learning-direction').then(res => {
			this.genderListTo = res.data.data.learning_direction
		})
		// 获取学生历史操作记录
    system_history({ tbl_id: this.$route.query.id,tbl_name: 'student_account' }).then(res => {
      this.historyData = res.list || []
    })

	}
}
</script>

<style lang="scss" scoped>
.history-box {
	.step-box {
		position: relative;
	}

	.cir {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 15rem;
		height: 15rem;
		background-color: #1C4EFD;
		border-radius: 50%;
		z-index: 1;
	}

	.cir-line {
		position: absolute;
		top: 15rem;
		left: 50%;
		transform: translateX(-50%);
		width: 1rem;
		height: 100%;
		background-color: #ccc;
	}

	.div1 > div {
		word-break: keep-all;
		white-space: nowrap;
		padding-right: 40rem;
		height: 66rem;
		line-height: 66rem;
		padding-left: 20rem;
		color: #333;
	}

	&:last-child {
		.cir-line {
			display: none;
		}
	}
}
</style>
